<template>
  <div>
    <!--begin::Dashboard-->
    <!--    <v-row>-->
    <v-col cols="12" sm="12">
      <b-alert
          show
          variant="light"
          class="alert alert-custom alert-success alert-shadow fade show gutter-b mb-0"
      >
        <!--          <div class="alert-icon">-->
        <!--            <span class="svg-icon svg-icon-lg">-->
        <!--              <inline-svg src="media/svg/icons/Home/Home.svg"/>-->
        <!--            </span>-->
        <!--          </div>-->
        <div class="alert-text mt-2 text-center">
          <h1 style="color: #000000"><b>My Realty Cloud's Real Estate Management System</b></h1>
        </div>
      </b-alert>
    </v-col>
    <v-col cols="12" sm="12">
      <ListingsDashboardStats :viewFull="0" @tDevsPros="getTDevsPros"/>
    </v-col>
    <v-col cols="12" sm="12">
      <LeadsDashboardStats :viewFull="0"/>
    </v-col>
    <v-row>
      <v-col class="col-lg-6 col-sm-6">
        <v-card>
          <v-card-title>
            Trending Developments (Last Quarter)
          </v-card-title>
          <v-divider class="m-0"></v-divider>
          <div class="bg-white">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Title
                  </th>
                  <th class="text-left">
                    Transactions
                  </th>
                  <th class="text-left">
                    Sales
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(item, i) in tDevs"
                    :key="i"
                >
                  <td>
                    <a v-if="item.type === '0'" :href="'/developments-management/master-developments?development=' + item.id + '&t=1'">{{
                      item.full_title
                    }}</a>
                    <a v-if="item.type === '1'" :href="'/developments-management/sub-developments?development=' + item.id + '&t=1'">{{
                        item.full_title
                      }}</a>
                  </td>
                  <td>{{ item.total_transactions }}</td>
                  <td>{{ item.nice_total_sales }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-card>
      </v-col>
      <v-col class="col-lg-6 col-sm-6">
        <v-card>
          <v-card-title>
            Trending Projects (Last Quarter)
          </v-card-title>
          <v-divider class="m-0"></v-divider>
          <div class="bg-white">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Title
                  </th>
                  <th class="text-left">
                    Transactions
                  </th>
                  <th class="text-left">
                    Sales
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(item, i) in tPros"
                    :key="i"
                >
                  <td><a :href="'/developments-management/projects?project=' + item.id + '&t=1'"
                         >{{ item.full_title }}</a></td>
                  <td>{{ item.total_transactions }}</td>
                  <td>{{ item.nice_total_sales }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-card>
      </v-col>
    </v-row>


    <!--    </v-row>-->
  </div>
  <!--end::Dashboard-->
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import LeadsDashboardStats from "@/view/pages/realstate/dashboard/leads-inc/LeadsDashboardStats";
import ListingsDashboardStats from "@/view/pages/realstate/dashboard/listings-inc/ListingsDashboardStats";

export default {
  name: "dashboard",
  components: {ListingsDashboardStats, LeadsDashboardStats},
  data() {
    return {
      tDevs: [],
      tPros: [],
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Dashboard"}]);
  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
    getTDevsPros(devs, pros) {
      this.tDevs = devs
      this.tPros = pros
    }
  }
};
</script>
